import { lazy } from "react";

export const routes = [
  {
    path: "/",
    Component: lazy(() => import("../../pages/Dashboard")),
  },
  {
    path: "/user",
    Component: lazy(() => import("../../pages/user/List")),
  },
  {
    path: "/product",
    Component: lazy(() => import("../../pages/product/List")),
  },
  {
    path: "/product/item/:id",
    Component: lazy(() => import("../../pages/product/Item")),
  },
  {
    path: "/recommend",
    Component: lazy(() => import("../../pages/product/recommend/List")),
  },
  {
    path: "/order",
    Component: lazy(() => import("../../pages/order/List")),
  },
  {
    path: "/order/item/:id",
    Component: lazy(() => import("../../pages/order/Item")),
  },
  {
    path: "/change",
    Component: lazy(() => import("../../pages/order/change/List")),
  },
  {
    path: "/change/item/:id",
    Component: lazy(() => import("../../pages/order/change/Item")),
  },
  {
    path: "/care",
    Component: lazy(() => import("../../pages/order/care/List")),
  },
  {
    path: "/care/item/:id",
    Component: lazy(() => import("../../pages/order/care/Item")),
  },
  {
    path: "/seller",
    Component: lazy(() => import("../../pages/seller/List")),
  },
  {
    path: "/seller/item/:id",
    Component: lazy(() => import("../../pages/seller/Item")),
  },
  {
    path: "/menu",
    Component: lazy(() => import("../../pages/header/menu/List")),
  },
  {
    path: "/category",
    Component: lazy(() => import("../../pages/header/category/List")),
  },
  {
    path: "/banner",
    Component: lazy(() => import("../../pages/banner/List")),
  },
  {
    path: "/banner/item/:id",
    Component: lazy(() => import("../../pages/banner/Item")),
  },
  {
    path: "/banner/write",
    Component: lazy(() => import("../../pages/banner/Create")),
  },
  {
    path: "/event",
    Component: lazy(() => import("../../pages/event/List")),
  },
  {
    path: "/event/:type",
    Component: lazy(() => import("../../pages/event/ListByType")),
  },
  {
    path: "/event/item/:id",
    Component: lazy(() => import("../../pages/event/Item")),
  },
  {
    path: "/event/write",
    Component: lazy(() => import("../../pages/event/Create")),
  },
  {
    path: "/blog",
    Component: lazy(() => import("../../pages/blog/List")),
  },
  {
    path: "/blog/item/:id",
    Component: lazy(() => import("../../pages/blog/Item")),
  },
  {
    path: "/blog/write",
    Component: lazy(() => import("../../pages/blog/Create")),
  },
  {
    path: "/review",
    Component: lazy(() => import("../../pages/review/List")),
  },
  {
    path: "/review/item/:id",
    Component: lazy(() => import("../../pages/review/Item")),
  },
  {
    path: "/tradeIn",
    Component: lazy(() => import("../../pages/tradeIn/TradeInListPage")),
  },
  {
    path: "/tradeIn/detail/:id",
    Component: lazy(() => import("../../pages/tradeIn/TradeInDetailPage")),
  },
  // {
  //   path: "/trade/write",
  //   Component: lazy(() => import("../../pages/trade/Create")),
  // },
  {
    path: "/quote",
    Component: lazy(() => import("../../pages/tradeIn/quote/List")),
  },
  {
    path: "/cvs",
    Component: lazy(() => import("../../pages/tradeIn/cvs/List")),
  },
  {
    path: "/ep",
    Component: lazy(() => import("../../pages/Ep")),
  },
  {
    path: "/calculate",
    Component: lazy(() => import("../../pages/Calculate")),
  },
  {
    path: "/exhibition",
    Component: lazy(() => import("../../pages/exhibition/ExhListPage")),
  },
  {
    path: "/exhibition/create",
    Component: lazy(() => import("../../pages/exhibition/ExhCreatePage")),
  },
  {
    path: "/exhibition/:id/edit",
    Component: lazy(() => import("../../pages/exhibition/ExhEditPage")),
  },
  {
    path: "/exhibition/section",
    Component: lazy(() => import("../../pages/exhibition/ExhSecMgmtPage")),
  },
  {
    path: "/price",
    Component: lazy(() => import("../../pages/Price")),
  },
];
